import {ROUTES_NAMES} from '~/router/constants/Router.routesNames'

export const getPageTitle = (route: typeof ROUTES_NAMES[keyof typeof ROUTES_NAMES], t: any, params?: Record<string, string>, isRouteKey = false) => {
  const excludedRoutes = ['suggestionPage', 'buildingPage', 'presentationBuildingPage', 'presentationBuildingPageFromSuggestion', 'developerPage']

  const routeKey = Object.keys(ROUTES_NAMES).find(key => ROUTES_NAMES[key] === route)

  if (isRouteKey) {
    return routeKey
  }
  if (params && excludedRoutes.includes(routeKey)) {
    return t(`pageTitles.${routeKey}`, {value: params})
  }
  return t(`pageTitles.${routeKey}`)
}
